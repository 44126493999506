import React, { useState } from "react";
import { Button, TextField, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Modal as ModalAnt } from "antd";
import firebase from "firebase/app";
import axios from "axios";
import history from "history.js";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export default function PhoneVerify(props) {
  // const { phone, uid } = props.history.location.state;
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [phoneValue, setPhoneValue] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [isFetching, setIsFetching] = React.useState(false);
  const [checkPhone, setCheckPhone] = React.useState(false);
  const [myOTP, setMyOTP] = useState("aa");
  const [combinedOTP, setCombinedOTP] = useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackPhone, setOpenSnackPhone] = React.useState(false);

  const handleChange = (event) => {
    setPhoneValue(event.target.value);
  };

  const handleOTPChange = (event) => {
    setCombinedOTP(event.target.value);
  };
  React.useEffect(() => {
    async function getItem() {
      setIsFetching(true);

      let userInfo = await firebase.auth().currentUser;

      const { uid } = userInfo;

      await firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .onSnapshot((documentSnapshot) => {
          const userData = documentSnapshot.data();
          const { phone } = userData;
          setPhoneNumber(phone);
          setUserId(uid);

          var myOTP = Math.floor(100000 + Math.random() * 900000);
          var stringOTP = String(myOTP);

          console.log(stringOTP, "what sis sent");
          // var OTP = localStorage.getItem("OTP_KEY");
          setMyOTP(stringOTP);
          const messageOtp = `Your STOW verification code is: ${stringOTP}`;

          axios
            .get(
              `https://5vy4gz.api.infobip.com/sms/1/text/query?username=systemsadmin&password=@rticuL8in&from=STOW&to=${phone}&text=${messageOtp}`
            )
            .then(function (response) {})
            .catch((err) => {
              // Alert.alert(err);
              console.log(err);
            });
        });

      setIsFetching(false);
    }

    getItem();
  }, [checkPhone]);

  // React.useEffect(() => {
  //   var OTP = localStorage.getItem("OTP_KEY");
  //   setMyOTP(OTP);
  // }, []);

  const onSubmitPhoneNumber = async () => {
    if (!phoneValue.includes("+")) {
      setOpenSnackPhone(true);
    } else {
      await firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .update({
          phone: phoneValue.length > 5 ? phoneValue : phoneNumber,
        })
        .then(() => {
          setIsModalVisible(false);
          setCheckPhone(!checkPhone);
        });
    }
  };

  const handlePhoneVerification = async () => {
    // console.log(myOTP, 'myOTP');
    // console.log(combinedOTP, 'combinedOTP');

    if (myOTP != combinedOTP) {
      setOpenSnack(true);
      //   alert('Enter a valid OTP');
    } else {
      await firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .set(
          {
            phoneVerify: true,
          },
          {
            merge: true,
          }
        )
        .then(() => {
          window.history.back();
        });

      // Toast.info('Welcome to Stow ' + firstName);
      // await firebaseA
      //   .firestore()
      //   .collection('users')
      //   .doc(uid)
      //   .onSnapshot((documentSnapshot) => {
      //     setPhoneNumberIsValid(documentSnapshot.data().phoneVerify);
      //   });
    }

    // console.log(myOTP, 'myOTP');
    // console.log(combinedOTP, 'combinedOTP');
  };

  const sendCodeAgain = async () => {
    var myOTP = Math.floor(100000 + Math.random() * 900000);
    var stringOTP = String(myOTP);

    try {
      await localStorage.setItem("OTP_KEY", stringOTP);
      // alert(`Data successfully saved${stringOTP}`);
    } catch (e) {
      console.log("failed to save data");
    }

    const postData = {
      messages: [
        {
          from: "STOW",
          destinations: [{ to: `${phoneNumber}` }],
          // destinations: [{ to: '08159917828' }],
          text: `Your STOW verification code is: ${stringOTP}`,
        },
      ],
    };

    const axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization:
          "App ccc337e9cc34e3768d6edd11665f42c1-15e749d7-1c42-4aa3-8739-5f9da085adc4",
      },
    };
    axios
      .post(
        "https://zjdm8k.api.infobip.com/sms/2/text/advanced",
        postData,
        axiosConfig
      )
      .then(function (response) {
        console.log(JSON.stringify(response.status, "checked"));
      })
      .catch((err) => {
        console.log(err);
      });

    try {
      var OTP = await localStorage.getItem("OTP_KEY");
      setMyOTP(OTP);
    } catch (e) {
      console.log("failed to save date");
    }
  };

  const receiveCodeViaPhoneCall = async () => {
    var myOTP = Math.floor(100000 + Math.random() * 900000);
    var stringOTP = String(myOTP);

    try {
      await localStorage.setItem("OTP_KEY", stringOTP);
      alert(`You will receive a call shortly`);
      //   console.log(stringOTP, 'OLX');
    } catch (e) {
      alert("Failed to save the data to the storage");
    }

    const stringOTPToArr = stringOTP.split("");
    let firstNumber = stringOTPToArr[0];
    let secondNumber = stringOTPToArr[1];
    let thirdNumber = stringOTPToArr[2];
    let forthNumber = stringOTPToArr[3];
    let fifthNumber = stringOTPToArr[4];
    let sixthNumber = stringOTPToArr[5];
    const postData = {
      text: `Your  STOW  verification  code is:  ${firstNumber}, ${secondNumber}, ${thirdNumber},  ${forthNumber}, ${fifthNumber}, ${sixthNumber}, Your  STOW  verification  code  is:  ${firstNumber}, ${secondNumber}, ${thirdNumber},  ${forthNumber}, ${fifthNumber}, ${sixthNumber}`,
      language: "en",
      voice: {
        name: "Joanna",
        gender: "female",
      },
      from: "12068954700",
      to: phoneNumber,
    };

    const axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization:
          "App ccc337e9cc34e3768d6edd11665f42c1-15e749d7-1c42-4aa3-8739-5f9da085adc4",
      },
    };
    axios
      .post(
        "https://5vy4gz.api.infobip.com/tts/3/single",
        postData,
        axiosConfig
      )
      .then(function (response) {
        // console.log(JSON.stringify(response.status, 'checked'));
      })
      .catch((err) => {
        console.log(err);
      });

    try {
      var OTP = await localStorage.getItem("OTP_KEY");
      setMyOTP(OTP);
    } catch (e) {
      console.log("Failed to save the data to the storage");
    }
  };

  if (isFetching) {
    return (
      <div>
        <div>loading ......</div>
      </div>
    );
  } else {
    return (
      <div style={{ height: "100vh" }}>
        <div
          style={{
            textAlign: "center",
            color: "white",
            backgroundImage: `url(${require("../../../assets/images/loginBg.png")})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            //   opacity: 0.9,
          }}
        >
          <div style={{ color: "gray", fontSize: 20, marginBottom: 50 }}>
            Enter Your Verification Code
          </div>
          <div style={{ color: "gray", marginBottom: 100 }}>
            {`We sent a Verification code to ${phoneNumber}`}
          </div>

          <div style={{ marginBottom: 100 }}>
            <TextField
              required
              id='outlined-required'
              label='Enter OTP'
              placeholder='OTP'
              variant='outlined'
              onChange={handleOTPChange}
              name='OTP'
            />
          </div>

          <Button
            variant='contained'
            color='primary'
            className='capitalize'
            onClick={handlePhoneVerification}
          >
            VERIFY
          </Button>

          <div
            onClick={sendCodeAgain}
            style={{ color: "#007ac3", marginTop: 20, cursor: "pointer" }}
          >
            Resend OTP verification code
          </div>
          <div
            onClick={receiveCodeViaPhoneCall}
            style={{ color: "#007ac3", marginTop: 8, cursor: "pointer" }}
          >
            Receive verification code via phone call
          </div>
          <div
            onClick={() => setIsModalVisible(true)}
            style={{ color: "#007ac3", marginTop: 8, cursor: "pointer" }}
          >
            Did not receive OTP? Update your phone number
          </div>
        </div>
        <ModalAnt
          title='Update phone number.'
          visible={isModalVisible}
          onOk={onSubmitPhoneNumber}
          onCancel={() => setIsModalVisible(false)}
        >
          <div
            style={{
              paddingTop: 20,
              paddingBottom: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              style={{ width: "80%" }}
              required
              id='outlined-required'
              label='Phone number'
              variant='outlined'
              defaultValue={phoneNumber}
              onChange={handleChange}
              name='phone'
            />
          </div>
        </ModalAnt>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={() => setOpenSnack(false)}
        >
          <Alert onClose={() => setOpenSnack(false)} severity='error'>
            <div style={{ color: "white" }}>Enter a valid OTP</div>
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackPhone}
          autoHideDuration={6000}
          onClose={() => setOpenSnackPhone(false)}
        >
          <Alert onClose={() => setOpenSnackPhone(false)} severity='error'>
            <div style={{ color: "white" }}>Phone number must include "+"</div>
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
