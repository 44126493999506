import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./styles/_footer.css";

import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import CopyrightIcon from "@material-ui/icons/Copyright";

function HomeFooter(props) {
  return (
    <div className='footer-view'>
      <div>
        <div>
          <span className='footer-tabs'>
            {" "}
            <Link to='/about'>Abous us</Link>
          </span>
          <span className='footer-tabs'>
            {" "}
            <Link to='/contact'>Contact</Link>
          </span>
          <span className='footer-tabs'>
            <Link to='/faq'>FAQ </Link>
          </span>
          <span className='footer-tabs'>
            {" "}
            <Link to='/privacy'>Privacy Policy </Link>
          </span>
        </div>
        <div className='footer-icons-container'>
          <a href='https://www.facebook.com/octo5.co/' target='_blank'>
            <FacebookIcon
              style={{ width: 30, height: 30 }}
              className='footer-icon'
            />
          </a>

          <a href='https://www.twitter.com/octo5.co/' target='_blank'>
            <TwitterIcon
              style={{ width: 30, height: 30 }}
              className='footer-icon'
            />
          </a>

          <a href='https://www.instagram.com/octo5.co/' target='_blank'>
            <InstagramIcon
              style={{ width: 30, height: 30 }}
              className='footer-icon'
            />
          </a>
        </div>

        <div className='footer-copy-right'>
          Copyright{" "}
          <span className='footer-copy-right-span'>
            <CopyrightIcon style={{ fontSize: 12 }} />
          </span>{" "}
          STOW powered by Octo5 2021. All rights reserved
        </div>
      </div>
    </div>
  );
}

export default HomeFooter;
