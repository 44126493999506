import React from "react";
import { Card } from "antd";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import "../sections/styles/_faq.css";

const { Meta } = Card;

const FAQCard = (props) => {
  const { data } = props;

  console.log(data, "ggg");

  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div className={isOpen ? "faq-container" : "faq-container-mini"}>
      <div onClick={() => setIsOpen(!isOpen)} className='faq-title-container'>
        <div className='faq-title'>{data.q}</div>
        <div className='faq-expansion-icon'>
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
      </div>
      <div
        className={isOpen ? "faq-helper" : "faq-helper-none"}
        dangerouslySetInnerHTML={{ __html: data.a }}
      >
        {}
      </div>
    </div>
  );
};

export default FAQCard;
