const firebaseConfig = {
  // LIVE SERVER
  apiKey: "AIzaSyANAKdIUm0EZJkKzcCBpq1ROzMlRbr0mN4",
  authDomain: "stow-62251.firebaseapp.com",
  databaseURL: "https://stow-62251.firebaseio.com",
  projectId: "stow-62251",
  storageBucket: "stow-62251.appspot.com",
  messagingSenderId: "631799171633",
  appId: "1:631799171633:web:7a3ca2be2e4199551b2310",
  measurementId: "G-QFTT0893VK"

  // STOW DEV
  // apiKey: "AIzaSyC0Ud9Xl5mHIrxzDT8ZAjS4DytVbEZuf_Y",
  // authDomain: "octo5stowdev.firebaseapp.com",
  // databaseURL: "https://octo5stowdev-default-rtdb.firebaseio.com",
  // projectId: "octo5stowdev",
  // storageBucket: "octo5stowdev.appspot.com",
  // messagingSenderId: "11654968532",
  // appId: "1:11654968532:web:e6d91c468ba51e11ecb741",
  // measurementId: "11654968532",
};

export default firebaseConfig;
