import React from "react";
import "../sections/styles/_general.css";
import BottomImage from "../../../../assets/images/Rectangle.png";
import HomeFooter from "./HomeFooter";
const About = (props) => {
  // console.log(props);
  return (
    <div>
      <div className='general-container'>
        <div className='general-mainText'>About Us</div>

        <div className='general-helperText'>
          <p>
            STOW is a product of OCTO5 Holdings Limited; One of Nigeria's
            pioneer developers of gated commmunities. We are the prime source
            for innocation development and construction solutions designed to
            suit the needs of the Nigeria market.
          </p>
          <p>
            Our vision is to empower an increasing percentage of Nigerians by
            providing flexible and simplified solutions for home ownership
          </p>
          <p>
            The STOW targeted home purchase plans is an innovative response
            designed to ease the burden of home ownership in Nigeria. The STOW
            plan is designed to provide guaranteed homes; encourage a home
            savings culture and provide flexibility that enhances affordability
            to prospective home buyers and investors.
          </p>
        </div>
      </div>
      <img className='faq-bottom-image' src={BottomImage} alt='home image' />
      <HomeFooter />
    </div>
  );
};

export default About;
