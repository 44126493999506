import { MatxLoadable } from 'matx';

const PaymentForm = MatxLoadable({
  loader: () => import('./PaymentForm'),
});

const PaymentFormApp = MatxLoadable({
  loader: () => import('./PaymentFormApp'),
});

const NextPaymentFormApp = MatxLoadable({
  loader: () => import('./NextPaymentFormApp'),
});

const PaymentResponse = MatxLoadable({
  loader: () => import('./PaymentResponse'),
});

const NextPaymentResponse = MatxLoadable({
  loader: () => import('./NextPaymentResponse'),
});

const paymentRoutes = [
  {
    path: '/payment/new',
    component: PaymentFormApp,
  },
  {
    path: '/payment/next',
    component: NextPaymentFormApp,
  },
  {
    path: '/payment/summary',
    component: PaymentForm,
  },
  {
    path: '/payment/confirm',
    component: PaymentResponse,
  },
  {
    path: '/payment/confirmnext',
    component: NextPaymentResponse,
  },
];

export default paymentRoutes;
