import React from "react";
import { enquireScreen } from "enquire-js";

import Nav3 from "./Nav3";
import { Nav30DataSource } from "./data.source";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import "./less/_homeHeader.css";

// import { ReactComponent as CloseMenu } from '../../../assets/svg/x.svg';
import { ReactComponent as CloseMenu } from "../../../../assets/svg/x.svg";
import { ReactComponent as MenuIcon } from "../../../../assets/svg/menu.svg";
// import Logo from "../../../../assets/logo";
import Logo from "../../../../assets/images/Logo.png";

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location } = window;

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port,
      click: false,
    };
  }

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
  }

  handleClick = () => this.setState({ click: !this.state.click });
  closeMobileMenu = () => this.setState({ click: false });
  render() {
    const children = [
      <Nav3
        id='Nav3_0'
        key='Nav3_0'
        dataSource={Nav30DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      // <div
      //   className='templates-wrapper'
      //   ref={(d) => {
      //     this.dom = d;
      //   }}
      // >
      <div className='nav-header'>
        <div className='logo-nav'>
          <div className='logo-container'>
            <a
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textDecoration: "none",
                color: "white",
              }}
            >
              <Link to='/'>
                <img src={Logo} className='logo' />
              </Link>
            </a>
          </div>
          <ul
            className={this.state.click ? "nav-options active" : "nav-options"}
          >
            <li className='option' onClick={this.closeMobileMenu}>
              <Link to='/about'>About</Link>
            </li>
            <li className='option' onClick={this.closeMobileMenu}>
              <Link to='/contact'>Contact</Link>
            </li>
            <li className='option' onClick={this.closeMobileMenu}>
              <Link to='/faq'>FAQ</Link>
            </li>

            <li className='option mobile-option' onClick={this.closeMobileMenu}>
              <Link to='/signin'>Login</Link>
            </li>
          </ul>
        </div>
        <ul className='signin-up'>
          <li
            className='signup-btn option-login'
            onClick={this.closeMobileMenu}
          >
            <Link to='/signin'>Login</Link>
          </li>
        </ul>
        <div className='mobile-menu' onClick={this.handleClick}>
          {this.state.click ? (
            <CloseMenu className='menu-icon' />
          ) : (
            <MenuIcon className='menu-icon' />
          )}
        </div>
      </div>
      // </div>
    );
  }
}
