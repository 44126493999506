import { MatxLoadable } from "matx";
// import { authRoles } from "../../../auth/authRoles";

const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false
    },
    leftSidebar: {
      show: false,
      mode: "close"
    }
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false
    },
    navbar: { show: false }
  },
  secondarySidebar: { show: false },
  footer: { show: true }
};
const Privacy = MatxLoadable({
  loader: () => import("./Privacy")
})

const privacyRoutes = [
	{
    path: "/privacy",
    component: Privacy,
    settings
  }
];

export default privacyRoutes;
