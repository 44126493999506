import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import "../../views/home/sections/styles/_general.css";
import BottomImage from "../../../assets/images/Rectangle.png";
import HomeFooter from "../../views/home/sections/HomeFooter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    margin: "0 auto",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function Privacy() {
  const classes = useStyles();
  const [values, loading, error] = useCollectionData(
    firebase
      .firestore()
      .collection("agreement")
      .where("name", "==", "privacyPolicy"),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  return (
    <div>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <span>Loading...</span>}

      {values && values[0] && (
        <div className='general-container'>
          <div className='general-mainText'>Privacy Policy</div>
          <div
            className='general-helperText'
            dangerouslySetInnerHTML={{
              __html: values[0].text,
            }}
          ></div>
        </div>
      )}
      <img className='faq-bottom-image' src={BottomImage} alt='home image' />
      <HomeFooter />
    </div>
  );
}
