import React from "react";
import { Card } from "antd";

import "../sections/styles/_explore.css";

const { Meta } = Card;

const HomeCard = (props) => {
  const { image, mainText, helperText } = props;
  return (
    <div className='expore-content-card'>
      <img
        src={image}
        alt='play STOW intro image'
        className='home_card_image'
      />
      <div className='home_card_title'>{mainText}</div>
      <div className='home_card_helper'>{helperText}</div>
    </div>
  );
};

export default HomeCard;
