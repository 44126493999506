import Home from "./Home";

import FAQ from "./sections/FAQ";
import About from "./sections/About";
import Contact from "./sections/Contact";
import Privacy from "../privacy/Privacy";

const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false,
    },
    leftSidebar: {
      show: false,
      mode: "close",
    },
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false,
    },
    navbar: { show: false },
  },
  secondarySidebar: { show: false },
  footer: { show: true },
};

const homeRoutes = [
  {
    path: "/home",
    component: Home,
    settings,
  },
  {
    path: "/faq",
    component: FAQ,
    settings,
  },
  {
    path: "/contact",
    component: Contact,
    settings,
  },
  {
    path: "/about",
    component: About,
    settings,
  },
  {
    path: "/privacy",
    component: Privacy,
    settings,
  },
];

export default homeRoutes;
