import React from "react";
import "../sections/styles/_contact.css";
import messageImg from "../../../../assets/images/asset1.png";
import BottomImage from "../../../../assets/images/Rectangle.png";
import HomeFooter from "./HomeFooter";

const Contact = (props) => {
  // console.log(props);
  const contactData = [
    {
      title: "Send us a mail",
      link: "mailto:askme@stow.ng",
    },
    {
      title: "Instagram",
      link: "https://www.instagram.com/octo5co/",
    },
    {
      title: "Facebook",
      link: "https://www.facebook.com/octo5co/",
    },
    {
      title: "Twitter",
      link: "https://www.twitter.com/octo5co/",
    },
  ];
  return (
    <div>
      <div className='contact-container'>
        <div className='contact-container-header'>
          <div className='contact-container-mainText'>Say Hello</div>
          <img
            src={messageImg}
            alt='message email image'
            className='contact-message-image'
          />
        </div>

        <div className='contact-container-helperText'>
          We'll be happy to answer all your questions or
        </div>
        <div className='contact-container-helperText'>
          enquires. Feel free to say Hello.
        </div>

        <div className='contact-communication-container'>
          {contactData.map((item, index) => {
            return (
              <div key={index} className='contact-communication-card'>
                <a href={item.link} target='_blank'>
                  {item.title}
                </a>
              </div>
            );
          })}
        </div>
      </div>
      <img className='faq-bottom-image' src={BottomImage} alt='home image' />
      <HomeFooter />
    </div>
  );
};

export default Contact;
