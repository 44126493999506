import * as React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Row, Col } from "antd";
import { Frame, Page, Stack, Scroll, useCycle } from "framer";

const transition = { duration: 0.3, ease: [0.13, 0.23, 0.23, 0.23] };

const thumbnailVariants = {
  initial: { scale: 0.9, opacity: 0 },
  enter: { scale: 1, opacity: 1, transition },
  exit: {
    scale: 0.3,
    opacity: 0,
    transition: { duration: 0.2, ...transition },
  },
};

const frameVariants = {
  hover: { scale: 0.95 },
};

const imageVariants = {
  hover: { scale: 1.1 },
};

const Thumbnail = ({ serie }) => {
  const { name, description, img } = serie;
  return (
    <motion.div className='thumbnail' variants={thumbnailVariants}>
      <motion.div
        className='frame'
        whileHover='hover'
        variants={frameVariants}
        transition={transition}>
        <motion.img
          src={img}
          alt={description}
          variants={imageVariants}
          transition={transition}
        />
      </motion.div>
    </motion.div>
  );
};

const series = [
  {
    name: "Quartz",
    description: "Mass Affordable",
    img:
      "https://firebasestorage.googleapis.com/v0/b/stow-62251.appspot.com/o/web%2Fassets%2Fimg%2FQuartz.png?alt=media&token=79a02ef5-32c1-47c6-893c-8f0c0f7fff5e",
  },
  {
    name: "Sapphire",
    description: "Luxury",
    img:
      "https://firebasestorage.googleapis.com/v0/b/stow-62251.appspot.com/o/web%2Fassets%2Fimg%2FSapphire.png?alt=media&token=c0bce6cb-a112-4115-ab6c-8d0da36e6d7e",
  },
  {
    name: "Emerald",
    description: "Premium",
    img:
      "https://firebasestorage.googleapis.com/v0/b/stow-62251.appspot.com/o/web%2Fassets%2Fimg%2FEmerald.png?alt=media&token=4b508317-1a10-42c2-af2f-607527ae36f9",
  },
];

const SeriesGrid = () => (
  <>
    <div className='gallery' id='residential-segment'>
      <motion.div
        className='thumbnails'
        initial='initial'
        animate='enter'
        exit='exit'
        variants={{ exit: { transition: { staggerChildren: 0.1 } } }}>
        <h2
          className='center'
          style={{
            margin: "1em",
          }}>
          Our Residential Segments
        </h2>
        <Row spacing={8}>
          {series.map((serie, i) => (
            <Col key={i} xl={8} lg={8} md={8} sm={24} xs={24}>
              <Thumbnail key={i} serie={serie} />
            </Col>
          ))}
        </Row>
      </motion.div>
    </div>
  </>
);

export default SeriesGrid;
