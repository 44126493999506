import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import FAQExpansionPanel from "../components/FAQExpansionPanel";
import faqs from "../components/faqs";
import HeaderHome from "../HeaderHome/index";
import BottomImage from "../../../../assets/images/Rectangle.png";
// import HomeFooter from "./sections/HomeFooter";
import HomeFooter from "./HomeFooter";
// import FAQCard from '../components/FAQCard'

import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";

import SearchIcon from "@material-ui/icons/Search";

import "../sections/styles/_faq.css";
import FAQCard from "../components/FAQCard";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "0 auto",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function FAQ() {
  const classes = useStyles();
  // const [values, loading, error] = useCollectionData(
  //   firebase.firestore().collection("faq"),
  //   {
  //     snapshotListenOptions: { includeMetadataChanges: false },
  //   }
  // );

  const [searchValue, setSearchValue] = React.useState("");
  const [faqResultData, setFaqResultData] = React.useState([]);
  const [searchedData, setSearchedData] = React.useState([]);
  const [faqLoading, setFaqLoading] = React.useState(false);

  React.useEffect(() => {
    async function getFaq() {
      setFaqLoading(true);
      firebase
        .firestore()
        .collection("faq")
        .get()
        .then((querySnapshot) => {
          var data = [];
          querySnapshot.forEach((documentSnapshot) => {
            data.push(documentSnapshot.data());
          });
          setSearchedData(data);
          setFaqResultData(data);
          setFaqLoading(false);
        });
    }
    getFaq();
  }, []);

  const handleChange = (event) => {
    setSearchValue(event.target.value);
    let theSearch = event.target.value;
    const searchedResult = faqResultData.filter((item, index) => {
      return item.q.toLowerCase().includes(theSearch);
    });

    if (searchValue < 1) {
      setSearchedData(faqResultData);
    } else {
      setSearchedData(searchedResult);
    }
  };

  console.log(searchValue, "changing");

  if (faqLoading) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <div className='faq-home-container'>
          {/* {error && <strong>Error: {JSON.stringify(error)}</strong>} */}
          {faqLoading && <span>Loading...</span>}
          <div className='faq-home-header'>
            <div className='faq-support-text-have-question'>
              Do you have questions?
            </div>
            <div className='faq-support-text'>
              We offer you the flexibility to own and invest in homes
            </div>
          </div>

          <div className='faq-input-container'>
            <SearchIcon style={{ fontSize: 50 }} className='faq-search-icon' />
            <input
              className='faq-input'
              type='text'
              value={searchValue}
              onChange={handleChange}
              placeholder='type your question'
            />
          </div>
          {/* {values && <FAQExpansionPanel data={values && searchedData} />} */}
          {searchedData.map((item, index) => {
            return <FAQCard data={item} />;
          })}

          <div className='faq-content-container'>
            <div className='faq-need-help'>Still Need Help</div>
            <div className='faq-support-text'>
              Our team is happy to answer your questions. Contact us
            </div>
            <div className='faq-support-text'>
              and we'll be in touch as soon as possible
            </div>

            <div className='faq-support-contact'>
              <Link to='/contact'>Contact Support</Link>
            </div>
          </div>

          {/* <div className='faq-home-container-view'></div> */}
        </div>
        <img className='faq-bottom-image' src={BottomImage} alt='home image' />
        <HomeFooter />
      </div>
    );
  }
}
