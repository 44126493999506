import React from "react";
import { Row, Col, Button } from "antd";
import { motion } from "framer-motion";
import history from "history.js";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";

import "./styles/_hero.css";
import playBtn from "../../../../assets/images/play-button.png";
import newAppHandImg from "../../../../assets/images/new-app-hand.png";
import bottomImg from "../../../../assets/images/Rectangle.png";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid transparent",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 0, 0),
  },
}));

const Hero = (props) => {
  // console.log(props);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <div className='heroContainer hero_container_view'>
        <motion.div
          initial={{
            opacity: 0,
            y: 80,
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
        >
          <div>
            <div className='hero_header'>Unlock Your</div>
            <div className='hero_header'>Hidden Potentials</div>
            <div className='hero_subHeader'>
              Own a Home with our <span>home purchase plan</span>
            </div>
            <div className='hero_content'>
              <div
                className='hero_btn'
                onClick={() => {
                  history.push("/signup");
                }}
              >
                Get started
              </div>
              <div onClick={handleOpen} className='hero_howItWorks'>
                See how it works
              </div>

              <img
                onClick={handleOpen}
                src={playBtn}
                alt='play STOW introduction video'
                className='hero_image'
              />
            </div>
          </div>
        </motion.div>

        <motion.img
          initial={{
            opacity: 0,
            y: 80,
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          src={newAppHandImg}
          className='hero_stow_img'
        />
      </div>
      {/* <img
        src={bottomImg}
        alt='play STOW intro video'
        className='hero_bottom_img'
      /> */}

      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <iframe
              className='hero-play-video'
              // src='https://www.youtube.com/embed/OmBxV6OunXo?&autoplay=1'
              src='https://www.youtube.com/embed/bsqRAV8t7P4?&autoplay=1'
              frameborder='0'
              allow='autoplay; encrypted-media'
              allowfullscreen
            ></iframe>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Hero;
