import React from "react";
import HomeCard from "../components/HomeCard";
// import playBtn from "../../../../assets/images/qualification.png";
import goalImg from "../../../../assets/images/goal2.png";
import shoppingBagImg from "../../../../assets/images/shopping-bag.png";
import salaryImg from "../../../../assets/images/salary.png";
import qualificationImg from "../../../../assets/images/qualification.png";

import group50 from "../../../../assets/images/Group50.png";
import layerImg from "../../../../assets/images/dayor.png";
import iosImg from "../../../../assets/images/ios-img.png";
import androidImg from "../../../../assets/images/android-img.png";

import "./styles/_explore.css";

function Explore(props) {
  const data = [
    {
      image: goalImg,
      mainText: "View progress of project development",
      helperText: "",
    },
    {
      image: shoppingBagImg,
      mainText: "Purchase your home via the app",
      helperText: "",
    },
    {
      image: salaryImg,
      mainText: "View Payment plans",
      helperText: "",
    },
    {
      image: qualificationImg,
      mainText: "Self prequalification for mortgages",
      helperText: "",
    },
  ];
  return (
    <div>
      <div className='explore_container'>
        <div className='explore_card_container'>
          {data.map((item, index) => {
            return (
              <HomeCard
                key={index}
                image={item.image}
                mainText={item.mainText}
                helperText={item.helperText}
              />
            );
          })}
        </div>

        <div className='explore_img_container'>
          <img
            src={group50}
            alt='play STOW intro video'
            className='explore_img'
          />
        </div>
      </div>

      <div className='explore_second_content_view'>
        <div className='explore_second_content'>
          <div className='explore_second_content_item'>
            <div>
              <div className='explore_second_content_title'>
                Download the STOW app
              </div>

              <div className='explore_second_content_title'>
                and begin your journey
              </div>
              <div className='explore_second_content_title'>
                towards home ownership
              </div>
            </div>
            <div className='explore_second_mobile_container'>
              <a
                href='https://apps.apple.com/ng/app/stow/id1525639962/'
                target='_blank'
              >
                <img
                  src={iosImg}
                  alt='Download STOW on apple store'
                  className='explore_mobile_img'
                />
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=com.octo5.stow'
                target='_blank'
              >
                <img
                  src={androidImg}
                  alt=' Download STOW on google play store'
                  className='explore_mobile_img'
                />
              </a>
            </div>
          </div>
          <div>
            <img
              src={layerImg}
              alt='play STOW intro video'
              className='explore_img_two'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Explore;
